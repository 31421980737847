<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Upload Study Material
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
               <div class="form-group row">
                <label
                  for="classFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Title</label
                >
                <div class="col-sm-8">
                 <input type="text" class = "form-control" placeholder="Title" v-model="form.title">
                </div>
              </div>

              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Class</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.class">
                    <option value disabled hidden>Select Class</option>
                    <option
                        v-for="(y,i) in class_list"
                        v-bind:key="i"
                        :value="y.class_id"
                    >{{y.class_standard}}</option>
                  </select>
                
                </div>
              </div>
              <!-- end form-group -->

             
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="subjectFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Subject</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.subject">
                    <option value disabled hidden>Select Subject</option>
                    <option
                        v-for="(y,i) in subject_list"
                        v-bind:key="i"
                        :value="y.subject_id"
                    >{{y.subject_name}}</option>
                  </select>
                
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Division</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.division">
                    <option value disabled hidden>Select Division</option>
                    <option
                        v-for="(y,i) in division_list"
                        v-bind:key="i"
                        :value="y.division_id"
                    >{{y.division}}</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->
              <div class="form-group row">
                <label
                  for="yearFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Year</label
                >
                <div class="col-sm-8">
                  <select class="form-control" v-model="form.year">
                    <option value disabled hidden>Select Year</option>
                    <option
                        v-for="(y,i) in year_list"
                        v-bind:key="i"
                        :value="y.ac_year_id"
                    >{{y.ac_year_description}}</option>
                  </select>
                </div>
              </div>
              <!-- end form-group -->
            
              <div class="form-group row">
                <label
                  for="yearFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >File</label
                >
                <div class="col-sm-8">
                    <input
                    type="file"
                    id="file_attachment"
                    ref="file_attachment"
                    v-on:change="handleFileUpload()"
                    placeholder
                  />
                </div>
              </div>

             

               <div class="form-group row">
                <label
                  for="yearFormControlSelect1"
                  class="col-sm-4 col-form-label"
                  >Description</label
                >
                <div class="col-sm-8">
                  <textarea  name="desc" class="form-control" cols="12" rows="4" v-model="form.description" placeholder="Description"></textarea>
                </div>
              </div>
            
             
              <!-- end form-group -->
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                <button type="button" class="btn btn-primary btn-md"  @click="save_data">Submit</button>
                  &nbsp;
                  <button type="submit" class="btn btn-light">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
//const base_url=process.env.VUE_APP_BASE_URL;

//const base_url=https://localhost:44331/;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      show: true,
      isCollapsed: true,
      teacher_list:[],
      class_list:[],
      division_list:[],
      subject_list:[],
      year_list:[],
      teacher:"",
      class_id:"",
      division:"",
      year:"",
      subject:"",
       form: {
        division: "",
        class: "",
        year: "",
        title:"",
        subject:"",
        description:"",
        file:"",
        file_attachment:"",

      },

    };
  },
  methods:{
    D_M_Y_formatdate(date_parm){
       let date = new Date(date_parm);
       let dd = date.getDate();
       let mm = date.getMonth() + 1;
       let yyyy = date.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        let new_date = dd+ '-' +mm+ '-' +yyyy;
        return new_date;
    },
    save_data(){
    
        this.isLoading =true;
        var filetype = this.file_attachment.type;
        var filepath = this.file_attachment.name;
        var data = {
          ContentType: filetype,
          FilePath: filepath
        }
        console.log(data);
       
        var promise = apis.add_PreSignedUrl(data).then(response => {
          let signedUrl = response.data;
           let x = this;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": filetype
            }
          };
          return axios.put(signedUrl, this.file_attachment,options);
        }).
        then(resp => {
          console.log(resp);
          if (resp.status == 200) {
            this.isLoading = false;
             this.$fire({
                text: "File Uploaded Sucessfully",
                type: "",
                timer: 3000,
                size:"xs",
                width:350,
                heightAuto: 'false'
              });
          } else {
            this.$fire({
              text: "Error in File Upload !!",
              type: "",
              timer: 3000,
              size:"xs",
              width:350,
              heightAuto: 'false'
            });
          }
        }).
        catch(error => {
          console.log(error);
        });

        var data = {
          'file_type':this.file_attachment.type,
          'file_path': this.file_attachment.name,
          'remarks': this.form.description,
          'school_id': this.form.school,
          'district_id': '00000000-0000-0000-0000-000000000000',
          'class': this.form.class,
          'division': this.form.division,
          'student': '00000000-0000-0000-0000-000000000000',
          'subject': this.form.subject,
          'teacher': '00000000-0000-0000-0000-000000000000',
          //'ac_year_id': this.form.year,
          'ac_year_id':'00000000-0000-0000-0000-000000000000',
          'is_active': this.form.is_active,
          'created_date': this.D_M_Y_formatdate(new Date())
        }
        
       var promise = apis.add_content(data)
       // axios.post('https://localhost:44331/Prod/api/Contents',  JSON.stringify(data))
       .then( response => {
          this.$fire({
              text: "Your Content Uploaded Successfully",
              type: "",
              timer: 3000,
              size:"xs",
              width:350,
              heightAuto: 'false'
            });
       }).catch( error => {
          console.log(error);
       });
    },
    handleFileUpload(){
       this.file_attachment = this.$refs.file_attachment.files[0];
       console.log(this.file_attachment);
    },
    get_teacher(){
       var promise = apis.list_Teachers().then( response => {
          this.teacher_list = response.data;
      }).catch( errror => {

      })
    },
    get_class(){
       var promise = apis.list_ClassMasters().then( response => {
          this.class_list = response.data;
      }).catch( errror => {

      })
    },
    get_year(){
       var promise = apis.list_AcademicYears().then( response => {
          this.year_list = response.data;
      }).catch( errror => {

      })
    },
    get_subject(){
       var promise = apis.list_Subjects().then( response => {
          this.subject_list = response.data;
      }).catch( errror => {

      })
    },
    get_division(){
       var promise = apis.list_DivisionMasters().then( response => {
          this.division_list = response.data;
      }).catch( errror => {

      })
    },
    onSubmit(event) {
        event.preventDefault();
        if(this.para == '' || this.para == undefined){
            var data = {
              'class_standard': parseInt(this.form.class_id)
            };
            console.log(data);
            var promise = apis.add_ClassMasters(data)
            .then( response => {
              this.$alert("Class Added Sucessfully");
              this.$router.go(-1);
            }).catch(error => {

            });
          
        }else{
            // edit API will be call
             var data = {
               'school_id': this.id,
                'school_name': this.form.schoolName,
                'address': this.form.schoolAddress,
                'contact_no': this.form.contact,
                'contact_person': this.form.contactPerson,
                'district_id': '00000000-0000-0000-0000-000000000000'
            };
            console.log(data);
            axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
              this.isLoading = false;
              
              this.$alert("School Updated Sucessfully");
              this.$router.go(-1);

            }).catch(error => {

            });
        }
           
    },
  },
  created(){
    this.get_subject();
    this.get_teacher();
    this.get_division();
    this.get_class();
    this.get_year();

  }
};
</script>
